
















































import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table.vue'
import OrderNumber from '@/components/orders/OrderNumber.vue'
import { firestore } from 'firebase/app'
import { UserOrderConverter } from '@/models/converters/UserOrderConverter'
import { UserOrder } from '@/models/UserOrder'
import { User } from '@/models/User'
import { UserConverter } from '@/models/converters/UserConverter'
import { TableHeader } from '@/models/TableHeader'

@Component({
  components: {
    OrderNumber,
    Table
  }
})
export default class AdminOrder extends Vue {
  headers: TableHeader[] = [
    { key: 'number', title: 'Bestellnummer' },
    { key: 'date', title: 'Datum' },
    { key: 'customer', title: 'Kunde' },
    { key: 'farm', title: 'Betrieb' },
    { key: 'status', title: 'Status' },
    { key: 'total', title: 'Summe' }
  ]
  query: firestore.Query<UserOrder> = firestore()
    .collectionGroup('orders')
    .withConverter(new UserOrderConverter())
    .orderBy('created_at', 'desc')
  users: { [id: string]: User } = {}

  async populateOrders(orders: UserOrder[]) {
    const promises = []
    for (const order of orders) {
      promises.push(this.populateOrder(order))
    }
    await Promise.all(promises)

    return orders
  }

  async populateOrder(order: UserOrder) {
    if (!this.users[order.id]) {
      const user = (
        await order.reference.parent.parent
          .withConverter(new UserConverter())
          .get()
      ).data()
      Vue.set(this.users, order.id, user)
    }
  }
}
