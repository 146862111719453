var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"query":_vm.query,"headers":_vm.headers,"populate":_vm.populateOrders},scopedSlots:_vm._u([{key:"number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"inline-flex",attrs:{"to":{
          name: 'admin/orders-detail',
          params: {
            userId: _vm.users[item.id].id,
            orderId: item.id
          }
        }}},[_c('OrderNumber',[_vm._v(_vm._s(item.number))])],1)]}},{key:"date",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("datetime")(item.createdAt)))]}},{key:"customer",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[(_vm.users[item.id].photoUrl)?_c('div',{staticClass:"flex-shrink-0 w-10 h-10"},[_c('img',{staticClass:"object-cover w-10 h-10 rounded-full",attrs:{"src":_vm.users[item.id].photoUrl,"alt":_vm.users[item.id].name + ' Avatar'}})]):_vm._e(),_c('div',{class:{ 'ml-4': _vm.users[item.id].photoUrl }},[_c('div',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(_vm._s(_vm.users[item.id].name))]),_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(_vm._s(_vm.users[item.id].email))])])])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.state === 'created')?_c('span',{staticClass:"inline-flex px-2 text-xs font-semibold text-yellow-800 bg-yellow-100 rounded-full"},[_vm._v(_vm._s(_vm._f("orderState")(item.state)))]):_vm._e(),(item.state === 'fulfilled')?_c('span',{staticClass:"inline-flex px-2 text-xs font-semibold rounded-full text-brand-800 bg-brand-100"},[_vm._v(_vm._s(_vm._f("orderState")(item.state)))]):_vm._e()]}},{key:"total",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.formattedTotal))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }